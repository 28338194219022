<template>



	<!-- <div class="hello"> -->
	<div class="content" :style="{'width':(windowWidth)+'px','height':(windowHeight)+'px'}">
		<div class="index-img">
			<div class="company-icon"></div>

			<div class="title-div">
				<label class="title1">网站正在搭建中</label>
				<label class="title2">The website is currently under construction</label>
			</div>
			<!-- <div class="frame-container">
				<img v-for="(image, index) in images" :key="index" :src="image" class="frame-image" />
			</div> -->
			<div class="gif-background">
				<img :src="url" style="width: 100%;height: 100%;" />
			</div>

		</div>
		<div class="index-beian title3" style="display: flex;align-items: center; justify-content: center;"><span><a href="https://beian.miit.gov.cn" target="_blank" style="text-decoration: none;color: #fff;">浙ICP备2024125440号-2</a></span></div>
	</div>
	
	<!-- </div> -->
</template>

<script>
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		mounted() {
			document.title = '蓝成应急';
		},
		data() {
			return {
				number: 0,
				url: 'https://estation.cdn.bcebos.com/lcyj/1-1_0080.png',
				previewUrl: '',
				img: null,
				start: 80,
				end: 140,
				index: 80,
				timer: undefined,
				interval: 200,
				loop: true,
				windowWidth: 0,
				windowHeight: 0,
				images: [
					'https://estation.cdn.bcebos.com/lcyj/1-1_0080.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0081.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0082.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0083.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0084.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0085.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0086.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0087.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0088.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0089.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0090.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0091.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0092.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0093.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0094.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0095.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0096.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0097.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0098.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0099.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0100.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0101.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0102.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0103.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0104.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0105.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0106.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0107.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0108.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0109.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0110.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0111.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0112.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0113.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0114.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0115.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0116.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0117.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0118.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0119.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0120.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0121.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0122.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0123.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0124.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0125.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0126.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0127.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0128.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0129.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0130.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0131.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0132.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0133.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0134.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0135.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0136.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0137.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0138.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0139.png',
					'https://estation.cdn.bcebos.com/lcyj/1-1_0140.png'
				],
			}
		},
		methods: {
			getWindow() {
				this.windowWidth = document.documentElement.clientWidth;
				this.windowHeight = document.documentElement.clientHeight;
				console.log(this.windowWidth);
				console.log(this.windowHeight);
			},
			init() {
				// this.img=document.getElementById('gif-container');
				// this.run();
				this.run2();
			},
			run() {
				// 清空定时器
				this.clearTimer()
				// 初始化图片
				this.change()
				// 开始倒计时
				this.timer = setInterval(() => {
					// 叠加
					this.index += 1
					// 超过最后一张了则重新开始
					if (this.index > this.end) {
						// 回到第一张
						this.index = this.start
						// 如果不循环则暂停（如果需要播放完成回调，在这里面添加即可）
						if (!this.loop) {
							this.clearTimer()
						}
					}
					// 切换图片
					this.change()
				}, this.interval)
			},
			run2() {

				setInterval(() => {
					this.url = this.images[this.number];
					this.number = this.number + 1;
					console.log(this.url);
					console.log(this.number);
					if (this.number == 61) {
						this.number = 0;
					}
				}, this.interval)
			},
			change() {
				// 设置图片
				this.previewUrl = 'https://estation.cdn.bcebos.com/lcyj/1-1_' + this.index.toString()
					.padStart(4, '0') + '.png';
				console.log(this.previewUrl);
				console.log() // '0001'

			},
			clearTimer() {
				if (this.timer) {
					clearInterval(this.timer)
					this.timer = undefined
				}
			}
		},
		created() {
			this.getWindow(), this.init();
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.frame-container {
		position: relative;
		width: 400px;
		/* 根据需要调整 */
		height: 400px;
		/* 根据需要调整 */
		overflow: hidden;
	}

	.frame-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		opacity: 0;
		animation: frameAnimation 1s linear infinite;
	}

	@keyframes frameAnimation {

		0%,
		100% {
			opacity: 0;
		}

		16.66%,
		16.67% {
			opacity: 1;
		}
	}

	h3 {
		margin: 40px 0 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	.index-img {
		position: relative;
		height: 74%;
		width: 100%;
		/* display: flex;
		justify-content: center;
		align-items: center; */

		background-image: url("https://lciot-system.oss-cn-hangzhou.aliyuncs.com/lcyj/group%402x.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		overflow-x: hidden;
		overflow-y: hidden;
	}
	.index-beian{
		height: 26%;
		width: 100%;
		
	}

	.company-icon {
		height: 54px;
		width: 211px;
		left: 4%;
		top: 6%;
		position: absolute;


		background-image: url("https://lciot-system.oss-cn-hangzhou.aliyuncs.com/lcyj/%E7%99%BD-%E8%93%9D%E6%88%90%E5%BA%94%E6%80%A5logo-02%402x.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.content {
		width: 100%;
		height: 100%;
		background: #262334;
	}

	.title1 {
		float: left;
		/* width: 392px; */
		/* height: 77px; */
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 50px;
		color: #FFFFFF;
		line-height: 77px;
		letter-spacing: 1px;
		text-align: left;
		font-style: normal;
	}

	.title2 {
		float: left;
		/* width: 481px; */
		/* height: 40px; */
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 20px;
		color: #9EA4B8;
		line-height: 40px;
		text-align: left;
		font-style: normal;
	}
	
	.title3 {
		float: left;
		/* width: 392px; */
		/* height: 77px; */
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 12px;
		color: #FFFFFF;
		line-height: 77px;
		letter-spacing: 1px;
		text-align: left;
		font-style: normal;
	}

	.title-div {

		left: 14%;
		top: 56%;
		position: absolute;
		width: 481px;
		height: 125px;
	}

	.gif-background {
		width: 400px;
		height: 400px;

		left: 58%;
		top: 36%;
		position: absolute;


	}
</style>